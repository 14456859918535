// extracted by mini-css-extract-plugin
export var gallery = "custom-blocks-module--gallery--gpyeE";
export var item = "custom-blocks-module--item--2I5k3";
export var button = "custom-blocks-module--button--3YAaC";
export var button_slim = "custom-blocks-module--button_slim--1IMTR";
export var card_link = "custom-blocks-module--card_link--3VOB0";
export var card_simple = "custom-blocks-module--card_simple--WPsZc";
export var image = "custom-blocks-module--image--3itwh";
export var details = "custom-blocks-module--details--1k8Nx";
export var excerpt = "custom-blocks-module--excerpt--2MWpX";
export var author = "custom-blocks-module--author--6noPE";
export var card_product = "custom-blocks-module--card_product--2XNvk";
export var description = "custom-blocks-module--description--3JkHw";